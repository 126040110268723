import * as React from 'react';
import classnames from 'classnames';
import {ProductName} from '../ProductName/ProductName';
import {Divider} from '../Divider/Divider';
import {ProductPrice} from '../ProductPrice/ProductPrice';
import {OutOfStock} from '../OutOfStock/OutOfStock';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {
  withTranslations,
  IProvidedTranslationProps,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {getProductWidgetSettingsFromProps} from '../../commons/styleParamsService';
import {WidgetAlignmentEnum} from '../../commons/enums';
import s from './ProductDetails.scss';

export interface IProductDetailsProps extends IProvidedGlobalProps, IProvidedTranslationProps {
  hideOnHover?: boolean;
}

export enum DataHook {
  Root = 'product-details',
  DividerId = 'divider',
}

class ProductDetailsComp extends React.Component<IProductDetailsProps> {
  public static defaultProps: Partial<IProductDetailsProps> = {
    hideOnHover: false,
  };

  public render(): React.ReactNode {
    const {
      globals: {product, shouldRenderPriceRange, experiments},
      hideOnHover,
    } = this.props;
    const settings = getProductWidgetSettingsFromProps(this.props);
    const showPreOrderPriceFT = experiments.showPreOrderPrice;
    const preOrderItem = product.inventory && product.inventory.availableForPreOrder;

    const showPrice =
      (product.isInStock && settings.priceToggle) ||
      (!product.isInStock && settings.priceToggle && preOrderItem && showPreOrderPriceFT);

    return (
      <div
        data-hook={DataHook.Root}
        className={classnames(s.productDetails, this.getAlignmentClass(settings.alignment), {
          [s.productDetailsHideOnHover]: hideOnHover,
        })}>
        {settings.titleToggle ? (
          <ProductName name={product.name} bottomGutter={settings.priceToggle || settings.separatorToggle} />
        ) : null}
        {settings.separatorToggle && <Divider data-hook={DataHook.DividerId} bottomGutter={settings.priceToggle} />}
        {showPrice && (
          <ProductPrice
            price={product.price}
            formattedPrice={product.formattedPrice}
            comparePrice={product.comparePrice}
            formattedComparePrice={product.formattedComparePrice}
            priceRange={shouldRenderPriceRange ? product.priceRange : undefined}
            formattedPricePerUnit={product.formattedPricePerUnit}
            pricePerUnitData={product.pricePerUnitData}
          />
        )}
        {!showPrice && !product.isInStock && settings.priceToggle ? <OutOfStock /> : null}
      </div>
    );
  }

  private getAlignmentClass(alignment: WidgetAlignmentEnum): string {
    switch (alignment) {
      case WidgetAlignmentEnum.LEFT:
        return s.leftText;
      case WidgetAlignmentEnum.RIGHT:
        return s.rightText;
      case WidgetAlignmentEnum.CENTER:
      default:
        return s.centerText;
    }
  }
}

export const ProductDetails = withGlobalProps(withTranslations('globals.texts')(ProductDetailsComp));
